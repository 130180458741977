// export const backend_url = "http://localhost:4000";
export const backend_url = "https://vampire-coders-production.up.railway.app";

// https://vampire.up.railway.app/admin/register

// https://vampire.up.railway.app/post/searchJobs/?title=dev&location=De
// https://vampire.up.railway.app/post/getPostDetails/644586a78f4851e11e2e9ba9

// localhost:4000/quiz/getAllQuizForCategory?categoryId=6536d4fe1baed988a6fc45f0
// localhost:4000/quiz/getQuizById/6536dbf1320b7a947a4c9d62
